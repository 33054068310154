import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AboutPageView from '../views/aboutPage/index';

const AboutPage = () => (
  <Layout>
    <SEO title="About" description="About Suave Way's Mission and Vision" />
    <AboutPageView />
  </Layout>
);

export default AboutPage;
