import React, { Component } from 'react';
import styled from 'styled-components';
import SideShareButtons from '../../components/SideShareButtons';

const PageContainer = styled.div`
  width: 100%;
  box-shadow: 0px 50px 25px 52px rgba(255, 255, 255, 1);
  background: white;
`;

const Label = styled.h1`
  text-align: center;
  padding-top: 50px;
`;

const QuoteContainer = styled.div`
  margin: 80px auto 30px;
  width: 600px;
  background: white;
  box-shadow: 0px 0px 53px 52px rgba(255, 255, 255, 1);
  @media (max-width: 900px) {
    width: 90%;
  }
`;

const Quote = styled.p`
  letter-spacing: 2.5px;
  line-height: 25px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
`;

const TextContainer = styled.div`
  margin-top: 60px;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 80px;
  @media (max-width: 900px) {
    width: 90%;
  }
`;

const T = styled.p`
  letter-spacing: 1.2px;
  line-height: 28px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 25px;
`;
export default class AboutPageView extends Component {
  render() {
    return (
      <PageContainer>
        <SideShareButtons slug={'/about'} />
        <Label>About Suave Way</Label>
        <QuoteContainer>
          <Quote>
            “I believe changing our lives starts by defeating our limiting
            beliefs and creating a new image of ourselves. If you don’t see
            yourself as a confident competent person, you start to abandon your
            amazing potential.”
          </Quote>
          <Quote
            style={{ fontWeight: 700, textAlign: 'right', paddingTop: '45px' }}
          >
            - Nicolas Tailor
          </Quote>
        </QuoteContainer>
        <TextContainer>
          <T>
            The Suave Way is the way to become a person with{' '}
            <b>
              a strong sense of purpose and self-esteem (not egotistical), a
              “suave” person
            </b>
            .
          </T>
          <T>
            <b>
              You can't hope for this to happen to you, because it can only come
              from you.
            </b>
          </T>
          <T>And that is why:</T>
          <T>
            <b>My mission</b> is to create an encyclopedia with the knowledge
            I’ve gathered by fighting my own battles with social anxiety for
            years,
            <b>
              here you will learn how to develop your beliefs about yourself in
              a positive light and start your journey towards better mental
              health and relationships
            </b>
            .
          </T>
          <T>
            Once I started my path to becoming suave, I started receiving better
            opportunities, even without formal education I started working at
            “real jobs” that made “real money” and changed my life in unexpected
            ways.
          </T>
          <T>
            I started to notice the path I had taken,{' '}
            <b>a path to a warmer, brighter and happier life</b>. And{' '}
            <b>
              it all started with redefining my beliefs of where my limits laid,
              I now decided where I would place them
            </b>
            .
          </T>
          <T>
            By developing our{' '}
            <b>
              self confidence which is built upon positive beliefs on ourselves
            </b>
            , we start to build the base for amazing progress in our lives, we
            dare to make moves that are more rewarding and meaningful.
          </T>
          <T>The Suave Way is a way of life.</T>
        </TextContainer>
        <Label>Who am I?</Label>
        <TextContainer>
          <T>
            You can call me Nico, I'm using a pen name of "Nicolas Tailor"
            because I prefer to keep my identity private.
          </T>

          <T>
            I'm a blogger with a tech background and through my earlier years I
            suffered from social anxiety and lacked people skills. That made my
            life really hard as I had to constantly check in with my team and
            work together to create solutions.
          </T>

          <T>
            During that time of my life I collected and studied a lot of useful
            knowledge from sources like ex-fbi agents who developed ways of
            building rapport with enemies, entrepreneurs that led companies to
            incredible futures and psycologists helping people find their
            purposes in life.
          </T>

          <T>
            I feel my purpose is to learn how to build the life that you want to
            live, and I started to create my own set of beliefs and habits
            surrounding the information I collected over the years.
          </T>

          <T>
            <strong>
              This blog isn't only about social skills, it is about the journey
              of transforming yourself into a the best version of yourself.
            </strong>
          </T>

          <T>Now get started!</T>
        </TextContainer>
      </PageContainer>
    );
  }
}
